import React from "react"
import Layout from "../components/layout"
import { Row, Shim, PageTitle } from "../components/row"

export default () => (
  <Layout>
    <Row>
      <PageTitle>Terms &amp; Conditions</PageTitle>
      <Shim>
        <p>
          <strong>Cancellations:</strong> for all orders are unavailable, if your event has been cancelled, postponed and/or downsized (even in the event of a catastrophic event and/or out of the ordinary circumstances) Frosted Cakery does NOT offer refunds and/or money back. We will need at least 30 days notice for any changes needed to your order, this will then be evaluated by Frosted on a case by case basis and will be at the full discretion of Frosted as to what, if any, changes and/or postponements can be made or accommodated. IF the client fails to notify us within 30 days prior to the event date, the order will be fulfilled as planned and the customer will be obligated to pick up their order on the specified date on their order.
        </p>
        <p>
          <strong>Payment agreement:</strong> All orders require a minimum of 30% down payment of the full total and are due upon order placement. The 30% deposit is non-refundable. Order balances are due no later than 30 days prior to the event date. Additions or new sign ups less than two weeks prior to the event require payment in full at the time of order placement and are non-refundable with no exceptions, changes, alterations and/or cancellations.
        </p>
        <p>
          <strong>Rush Order:</strong> All rush orders (within one week of the event) fees may be added to accommodate the paid overtime to complete the order in time. No refunds, exchanges and/or cancellations are available for rush orders.
        </p>
        <p>
          <strong>Refunds:</strong> The events surrounding a customer request for a refund will be evaluated by Frosted on a case by case basis and will be at the full discretion of Frosted as to what, if any, refunds will be awarded based on common industry practices. The 30% deposits are NON-refundable regardless. If the order is canceled within 30 days of the event date there is no refund available.
        </p>
        <p>
          <strong>Allergy Notice:</strong> While we offer a variety of allergy
          free products and do our BEST to be careful, we CAN NOT prevent cross
          contamination and will not be held liable for any allergic reactions.
          Frosted Cakery is a facility that prepares foods that may contain
          these ingredients: milk, eggs, wheat/gluten, soybean, peanuts and
          tree-nuts. The person(and or legal guardian of) with the allergy is
          the only person able to place an allergy free order with us.
        </p>
        <p>
          <strong>Color matching and variables:</strong> We at Frosted do our
          very best to match color swatches provided by our customers. However,
          matching colors presents many variables as the color ingredients cool,
          harden and set for periods of time in various environments. While our
          success rate is very high, guarantees of consistent color matching
          100% of the time are unrealistic. For this reason Frosted does not
          guarantee exact color matching. To improve the probability of matching
          specific colors we have developed the following “Do’s and Don’ts”
          list:
        </p>
        <ol>
          <li>
            Live swatches only – no emailed, verbally communicated or internet
            sample colors – we suggest we develop agreements “in person” to help
            assure we “see” the actual colors together;
          </li>
          <li>
            Purple, Navy Blues and other similar colors can change ‘shades’ when
            exposed to differing weather, sunlight, heat, humidity, moisture,
            etc. We will give these colors our best efforts.
          </li>
          <li>
            We recommend that colors always be kept in as stable an environment
            (if possible) from the time of production, delivery, presentation
            and consumption.
          </li>
        </ol>
        <p>
          We have discovered that these steps improve the likelihood of success
          with color matching.
        </p>
        <p>
          <strong>Security Deposit Agreement:</strong> All rentals require a
          deposit equal to the replacement cost of the equipment being rented
          (i.e. cake stands, cupcake towers, plates, jars, etc.). This deposit
          is due at the same time the down payment is made (see Payment
          Agreement) in the form of cash, personal check, or credit card number.
          The equipment must be returned on the agreed date damage free or the
          deposit will be used to purchase replacement equipment. If the
          rental(s) are returned in the same condition received by the borrower,
          the cash or check will be returned to the payee, and/or the credit
          card number will be destroyed in front of the card holder.
        </p>
        <p>
          <strong>Equipment/Rental return:</strong> Return to Frosted Cakery no
          later than M T W Th F S Sn <br />
          Date:__________
          <br />
          We follow standard practices for equipment/rental agreements in that
          we return all deposited funds upon the return of equipment, unless the
          equipment is broken or damaged at which time we use the deposited
          funds to replace and/or repair any and all damages so our customers
          will continue to enjoy the highest level of quality equipment
          available.
        </p>
        <p>
          <strong>Finalizing Orders:</strong> All orders are considered FINAL 30
          days prior to the event. In order to give our customers the ultimate
          products and services ANY changes inside of the 30 days prior to the
          event including but not limited to size, amount, flavor, design, etc.
          [if even deemed possible] will require cash payment to cover all
          additional costs in labor, supplies, expediting, facilities and
          schedule changes. Charges inside the final 30 days prior to the event
          are due in full upon ordering. Note: All refunds are forfeited on
          canceled orders within the 30 days.
        </p>
        <p>
          <strong>Outdoor Events and Weather:</strong> Frosted Cakery is NOT
          responsible for any weather damage to products after the customer has
          taken possession. Weather conditions also can determine the delivery
          arrival time, regardless of the previously determined contracted time.
          Frosted will make the best decision as to arrival time considering
          weather conditions and its severity such as; heat, wind, humidity,
          rain and so forth. That being said, we are NOT responsible for the
          product being damaged from extreme weather conditions. Frosted
          provides insight and guidelines to avoid adverse effects of various
          weather conditions as follows:
        </p>
        <ul>
          <li>
            <strong>HEAT:</strong> Buttercream/Whipped Cream/Fresh Fruit cakes
            can melt and therefore are not recommended for outdoor events in
            temperatures exceeding 80 degrees (which are common in the months
            May through October).
          </li>
          <li>
            <strong>HUMIDITY:</strong> Fondant Cakes do not do well in humidity,
            they can sag and/or bubble.
          </li>
          <li>
            <strong>WIND:</strong> Wind can push product over no matter how big
            or small. The wind may blow debris into cake (or other desserts).
          </li>
          <li>
            <strong>RAIN:</strong> Fondant can be spotted by rain. Rain can
            change or melt colors or product structure.
          </li>
        </ul>
        <p>
          <strong>Leveling &amp; Stabilizing Tables:</strong> Frosted Cakery is
          not responsible for leveling or stabilizing tables or platforms that
          Frosted products are placed upon; or for products placed on tables
          located in direct sunlight. Frosted Cakery will move the table to a
          safe/better location if product is not safe. We are happy to recommend
          what works best for presenting Frosted products properly and safely.
        </p>
        <p>
          <strong>Flowers, Toppers, and/or Cake Stands:</strong> In order to
          protect our customer’s best interests ALL flowers, toppers, and/or
          cake stands not provided by Frosted Cakery must be approved by Frosted
          prior to use. Approved flowers, toppers and/or cake stands should be
          delivered to the Frosted facility a week before the event. If the
          customer fails to bring in the topper, flowers and/or stands for
          approval but leaves these items onsite to be used for the cake,
          Frosted is NOT obligated to use any flowers, stand and/or topper on or
          around the cake if Frosted deems it unsuitable for use.
        </p>
        <p>
          <strong>Handling NO NO’s:</strong> Holding cakes in your lap while
          driving; placing products on unlevel seats in a vehicle; leaving
          products unattended in a vehicle; keeping products in vehicles for
          extended periods of time; placing products in a over filled trunk; not
          leveling and securing the product in a vehicle properly before
          traveling; placing the product where other objects can fall on it;
          placing the product around small children or animals; holding the cake
          box on the sides rather than the center of the bottom of the box,
          etc..
        </p>
        <p>
          <strong>Storage NO NO’S:</strong> Putting cake (or desserts) in a WET
          or HUMID refrigerator. Frosted does NOT recommend leaving your product
          in a restaurant refrigerator that is WET or HUMID. Some refrigeration
          may cause melting of items, and or items falling off cakes and/or
          desserts. Leaving your product unrefrigerated overnight or for
          extended periods of time (this can cause distortions and changes in
          the product).
        </p>
        <p>
          <strong>Customer Alternates &amp; Final Approval:</strong> To
          accommodate the tight schedules of our customers Frosted Cakery allows
          customers to choose an alternate to pick up their order. In these
          cases, IT IS AGREED the person picking up the product has full
          authority and responsibility for APPROVING all product(s)
          requirements. The Customer Alternate can point out any specific
          inconsistencies prior to delivery so those inconsistencies can be
          corrected before final approval and final delivery. Upon confirmation
          of the order the Customer Alternate will sign the order copy as formal
          approval of the finished product(s) and take delivery. All payments
          and agreements are final after this.
        </p>
        <p>
          <strong>Delivery:</strong> In order to provide our customers with
          timely and accurate delivery Frosted Cakery requires accurate
          addresses supported by maps to the location prior to delivery; this
          also allows for accurate delivery quoats so our customers don’t
          experience any unexpected costs. To insure customer satisfaction the
          customer or someone designated by the customer must be at the delivery
          location at the scheduled time of arrival to allow Frosted to complete
          the delivery. A contact phone number of the person(s) who has
          appropriate access to the facility must be provided at least 24 hours
          prior to the scheduled delivery. If Frosted does not have access at
          the agreed time of arrival after 30 minutes the product will be
          returned to the Frosted location and will be available for customer
          pick-up. Additional fees will apply to additional delivery efforts.
          For possible changes for delivery arrival time refer to outdoor events
          above.
        </p>
        <p>
          <strong>Set-up:</strong> All set-ups are provided upon request of the
          customer who ordered the item(s). Frosted will go by the approved
          sketch of the layout for the cake(s) and/or dessert(s). If the table
          or other props are different than agreed and approved by the customer
          who ordered the item(s), Frosted may have to alter the existing layout
          to fit the situation as deemed most appropriate. Frosted may also
          withhold from finishing a set-up if the weather conditions are
          extreme, please refer to 'outdoor events' above. If Frosted can not
          finish the set-up as contracted due to the weather conditions, there
          are NO refunds.
        </p>
        <p>
          <strong>Storage of Product:</strong> All Frosted products must be
          stored as stated under Pick Ups: Handling and Storage No No’s. Frosted
          is not responsible for the improper storage of the products.
        </p>
        <p>
          <strong>Arrival Time:</strong> Frosted Cakery will have all item(s)
          for pick up ready at the agreed time of arrival on the order
          form/contract. NO early pick-ups unless otherwise approved by Frosted
          PRIOR to pick-up.
        </p>
        <p>
          _______ (initial) I have read and agree to Frosted Cakery’s Terms and
          Conditions.
        </p>
      </Shim>
    </Row>
  </Layout>
)
